import { Button, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

interface ErrorProps {
  statusCode?: number
}

export function Error({ statusCode: errorCode }: ErrorProps) {
  const { t } = useTranslation('common')

  return (
    <Stack justify="center" align="center" w="full" h="50vh" color="txtBlack">
      <Stack w="full" align="center" position="absolute" zIndex={0} marginTop={-20} />
      <Stack justify="center" align="center" w="full" h="full" gap={8} zIndex={1}>
        <VStack gap={2} textAlign="center">
          <Heading fontSize="3xl" textTransform="uppercase">
            {t(errorCode === 404 ? 'page-not-found-title' : 'universal-error-title')}
          </Heading>
          <Text fontSize="xl">
            {t(errorCode === 404 ? 'page-not-found-message' : 'universal-error-message')}
          </Text>
        </VStack>
        <Stack w="full" maxW="500px" align="center">
          <Button as={NextLink} variant="txtBlack" href="/" passHref>
            {t('back-to-homepage')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
